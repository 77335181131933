import React from 'react';
import './About.css'; 
import aboutImage from '../Components/2.jpg'; 

const About = () => {
  return (
    <div className="about-section">
      
      
      <img src={aboutImage} alt="About Us" className="about-image" />
      <div className="about-content">
      </div>
    </div>
  );
};

export default About;
