import React from 'react';
import './Shop.css';
import Navbar2 from '../Navbar2/Navbar2';
import Footer from '../Footer/Footer';
import ShopNow from '../Components/pillow-bed.jpg';
import pillowImage1 from '../Components/pillow222.jpg';
import pillowImage2 from '../Components/pillow223.jpg';
import pillowImage3 from '../Components/pillow224.jpg';
// import pillowImage4 from '../Components/pillow225.jpg';
import pillowImage5 from '../Components/pillow226.jpg';
import pillowImage6 from '../Components/pillow227.jpg';
import pillowImage7 from '../Components/pillow228.jpg';
import pillowImage8 from '../Components/WhatsApp_Image_2024-03-09_at_14.47.18_805fc7aa-removebg-preview.png';
import TopperImage1 from '../Components/Topper1.jpg';
import TopperImage2 from '../Components/IMG-20240603-WA0016.jpg';
import TopperImage3 from '../Components/IMG-20240603-WA0020.jpg';
import TopperImage4 from '../Components/image545.png';
// import TopperImage5 from '../Components/Topper5.jpg';
// import TopperImage6 from '../Components/Topper6.jpg';
import TopperImage7 from '../Components/Topper7.jpg';
import TopperImage8 from '../Components/Topper8.jpg';
import MattressImage50 from '../Components/5555.jpg';
import MattressImage51 from '../Components/RoleAway.jpg';
import MattressImage52 from '../Components/image52.jpg';
import MattressImage53 from '../Components/image53.jpg';
import MattressImage54 from '../Components/image54.jpg';
import MattressImage55 from '../Components/image55.jpg';
import MattressImage56 from '../Components/image56.jpg';
import MattressImage57 from '../Components/image57.jpg';
import MattressImage1 from '../Components/mattress1.jpg';
// import MattressImage2 from '../Components/mattress2.jpg';
import MattressImage3 from '../Components/mattress3.jpg';
import MattressImage4 from '../Components/mattress4.jpg';
// import MattressImage5 from '../Components/mattress5.jpg';
// import MattressImage6 from '../Components/mattress9 (1).jpg';
import MattressImage7 from '../Components/mattress7.jpg';
import MattressImage8 from '../Components/mattress8.jpg';

const Shop = () => {
  return (
    <div>
      <Navbar2 />
      <div className="pillow-container">
        <img src={ShopNow} alt="Shop" />
      </div>

      <div className='container'>
        <div className='custom_container'>
          <h2><b>Shop Now</b></h2>


          <div className="pillow-images row">
            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage1} alt="Topper 1" content="EuroTop Memory Foam Mattress" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage2} alt="Topper 2" content="Memory Foam Orthopadic Mattress " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage3} alt="Topper3" content="HR Soft Foam" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage4} alt="Topper 4" content="HR Orthopadic Soft Foam" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage1} alt="Pillow 1" content="Organic Natural Latex Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage2} alt="Pillow 2" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage3} alt="Pillow 3" content="Memory Foam Pillow with knitted Fabric" />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage4} alt="Pillow 4" content="" />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage5} alt="Pillow 5" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage6} alt="Pillow 6" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage7} alt="Pillow 7" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage8} alt="Pillow 8" content="Neck Memory Foam Pillow" />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage5} alt="Topper 5" content=" " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage6} alt="Topper 6" content="" />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage7} alt="Topper 7" content="Pocket Spring Mattress With knitted Fabric" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage8} alt="Topper 8" content=" Pocket Spring Mattress With Memory Foam (For Stars Hotel)" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage50} alt="protector1" content="Folding Mattress " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage51} alt="protector2" content=" Role Away Bed" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage52} alt="protector 3" content="Mattress Protector " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage53} alt="protector4" content=" Role Away Bed" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage54} alt="protector 5" content="Mattress Protector " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage55} alt="protector 6" content="Memory Foam Contour Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage56} alt="protector 7" content="Organic Natural Latex Sheet" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage57} alt="protector 8" content="Memory Foam pillow Standard" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage1} alt="mattress 1" content="Bonnel Spring Mattress" />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage2} alt="mattress  2" content=" " />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage3} alt="mattress  3" content="Rebounded Foam Mattress" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage4} alt="mattress 4" content="Orthopadic Mattress " />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage5} alt="mattress  5" content=" " />
            </div> */}

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage6} alt="mattress  6" content="" />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage7} alt="mattress 7" content="Pocket Spring Mattress With knitted Fabric " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage8} alt="mattress 8" content="Pocket Spring Mattress With More Bouncing " />
            </div>



          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
}

const PillowImage = ({ src, alt, content }) => {
  return (
    <div className="Topper-image">
      <img src={src} alt={alt} />
      <div className="Topper-details">
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Shop;
