import React from 'react';
import './Topper.css';
import Navbar2 from '../Navbar2/Navbar2';
import Footer from '../Footer/Footer';
import ToppersImage from '../Components/spacious-room-with-bed.jpg';
import TopperImage1 from '../Components/Topper1.jpg';
import TopperImage2 from '../Components/IMG-20240603-WA0016.jpg';
import TopperImage3 from '../Components/IMG-20240603-WA0020.jpg';
import TopperImage4 from '../Components/image545.png';
// import TopperImage5 from '../Components/Topper5.jpg';
// import TopperImage6 from '../Components/Topper6.jpg';
import TopperImage7 from '../Components/Topper7.jpg';
import TopperImage8 from '../Components/Topper8.jpg';

const Topper = () => {
  return (
    <div>
      <Navbar2 />
      <div className="Topper-container">
        <img src={ToppersImage} alt="Toppers" />
        <h2 className='text-center'><b>Topper</b></h2>
      </div>

      <div className='container'>
        <div className='custom_container'>
          <div className="Topper-images row">
          <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage1} alt="Topper 1" content="EuroTop Memory Foam Mattress" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage2} alt="Topper 2" content="Memory Foam Orthopadic Mattress " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage3} alt="Topper3" content="HR Soft Foam" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage4} alt="Topper 4" content="HR Orthopadic Soft Foam" />
            </div>


            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage5} alt="Topper 5" content=" " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage6} alt="Topper 6" content="" />
            </div> */}

<div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage7} alt="Topper 7" content="Pocket Spring Mattress With knitted Fabric" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={TopperImage8} alt="Topper 8" content=" Pocket Spring Mattress With Memory Foam (For Stars Hotel)" />
            </div>

          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

const PillowImage = ({ src, alt, content }) => {
  return (
    <div className="pillow-image">
      <img src={src} alt={alt} />
      <div className="pillow-details">
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Topper;
