import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '../Components/Facebook.svg';
import TwitterIcon from '../Components/X.svg';
import LinkedInIcon from '../Components/LinkedIn.svg';
import InstagramIcon from '../Components/Instagram.svg';
import Whatsapp from '../Whatsapp/Whatsapp';
import './Footer.css';

const Footer = () => (
  <div className="footer section__padding">
    <div className='container'>
      <div className='custom_container'>
        <br />
        <div className="footer-links row">
          <div className="footer-links_div col-lg-3 col-md-6">
            <p>ComfsyRiser Mattress</p>
            <p>B-85, Sector 65, Noida,</p>
            <p>Uttar Pradesh 201301, India</p>
            <p>Call: +91 9990131819</p>
            <p>Email: <a href="mailto:alfaimpex99@gmail.com">alfaimpex99@gmail.com</a></p>
          </div>
          <div className="footer-links_div col-lg-3 col-md-6">
            <Link to="/">Home</Link>
            <Link to="/Terms">Terms and Conditions</Link>
            <Link to="/Privacy">Privacy Policy</Link>
            <Link to="/Locate">Store Locator</Link>
            <Link to="/Shop">Shop Now</Link>
          </div>
          <div className="footer-social-icons col-lg-6 col-md-6">
            <p id='content'>Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.</p>
            <div id="social_icons">
              <h3 style={{ color: 'black' }}>Follow us</h3>
              <a href="https://www.facebook.com/your-facebook-page" target="_blank" rel="noopener noreferrer">
                <img src={FacebookIcon} alt="Facebook" />
              </a>
              <a href="https://twitter.com/your-twitter-handle" target="_blank" rel="noopener noreferrer">
                <img src={TwitterIcon} alt="Twitter" />
              </a>
              <a href="https://www.linkedin.com/in/md-aslam-769808270?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app " target="_blank" rel="noopener noreferrer">
                <img src={LinkedInIcon} alt="LinkedIn" />
              </a>
              <a href="https://www.instagram.com/your-instagram-profile" target="_blank" rel="noopener noreferrer">
                <img src={InstagramIcon} alt="Instagram" />
              </a>
            </div>
            <Whatsapp />
          </div>
        </div>
        <div className="footer-copyright">
    <ul className="nav col-md-12 list-unstyled d-flex justify-content-end" style={{ fontSize: '12px',textAlign: 'right' }}>
        <span className="col-md-12 justify-content-end text-right">© 2024 ComfsyRiser Mattress.</span>
        <span className="footer-epfo text-right">Website Designed and Developed by Mezash Private Limited</span>
    </ul>
    <div className="disclaimer" style={{ fontSize: '12px', marginTop: '5px', textAlign: 'right' }}>
        Mezash Private Limited is not responsible for any copyright infringement of Images, Videos and Content.
    </div>
    <div className="version" style={{ textAlign: 'right', marginTop: '5px', fontSize: '12px'}}>
        [ Ver - 16.13.1. , 07-June-2024 ]
    </div>
</div>
<br/>
      </div>
    </div>
  </div>
);

export default Footer;
