import React from 'react';
import Img from '../Components/pic1 (11).jpg';
import Img1 from '../Components/hotel-textile-comfort-luxury-interior.jpg';
import Img2 from '../Components/double-bed-with-cushions.jpg';
import Img3 from '../Components/pic1 (1).jpg';

import './Product.css';

const productData = [
  {
    image: Img,
    heading: 'Super Active',
    // subHeading: 'collection ',
    // name: 'Springwel Foam Mattress',
    // price: '₹27,000',
    description: '',
  },
  {
    image: Img1,
    heading: 'Autograph',
    // subHeading: 'collection ',
    // name: 'Sleepwell Foam Mattress',
    // price: '₹18,000',
    description: '',
  },
  {
    image: Img2,
    heading: 'Club Class',
    // subHeading: 'collection ',
    // name: 'Evok Mattress',
    // price: '₹25,000',
    description: '',
  },
  {
    image: Img3,
    heading: 'ProActiv',
    // subHeading: 'collection ',
    // name: 'NeelKamal Mattress ',
    // price: '₹35,000',
    description: '',
  },
];

const ProductSection = () => {
  const handleAddToCart = (productName) => {
    console.log(`Added ${productName} to cart`);
  };

  return (
    <div className='container'>
      <div className='custom_container'>
        <div id="product-section">
          <div className="product-row row">
            {productData.map((product, index) => (
              <div key={index} className="product-item col-lg-3 col-md-6">
                <img
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <div className="product-info">
                  <h1 className="product-heading">{product.heading}</h1>
                  <p className="product-subheading">{product.subHeading}</p>
                  <div className="product-description">
                    {product.description.split('\n').map((line, lineIndex) => (
                      <p key={lineIndex}>{line}</p>
                    ))}
                  </div>
                  <p className="product-name">
                    {product.name}
                    <span className="rating">&#9733;&#9733;&#9733;&#9733;&#9733;</span>
                  </p>
                  <p className="product-price">{product.price}</p>
                  {/* <button
                className="add-to-cart-button"
                onClick={() => handleAddToCart(product.name)}
              >
                Shop Now
              </button> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;