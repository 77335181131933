import React from 'react';
import { Link } from 'react-router-dom';
import './Need.css';
import image1 from '../Components/Need1.png';
import image2 from '../Components/Peace-of-Mind1.png';
import image3 from '../Components/feel1.png';
import image4 from '../Components/talk.png';

const YourComponent = () => {

  const handleCallNow = () => {
    window.location.href = 'tel:+919990131819';
  };

  const handleChatNow = () => {
    window.open('https://wa.me/919990131819', '_blank');
  };

  return (
    <div className='section-background'>
      <div className='container'>
        <div className='custom_container'>
          <div className='need_section'>
            <div className="heading-row">
              <h1 >Need Help?</h1>
            </div>
            <div className="image-row row">
              <div className="image-container col-lg-3 col-md-6">
                <div className='img_flex'>
                  <img src={image1} alt="" />
                </div>
                <div className="image-content">
                  <h3>Chat with us</h3>
                  <p>
                    Ask away any of your sleep queries with our award-winning customer service Team.
                    
                  </p>

                  <button className="cta-button1" onClick={handleChatNow}>Chat now</button>

                </div>
              </div>
              <div className="image-container col-lg-3 col-md-6">
                <div className='img_flex'>
                  <img src={image2} alt="" />
                </div>

                <div className="image-content">
                  <h3>Peace of Mind Guaranteed</h3>
                  <p>
                    Our dedicated customer care team takes pride in their work & always focuses on providing the best support.
                    
                  </p>
                  <Link to="/Mind"  rel="noopener noreferrer"><button className="cta-button1">Learn More</button></Link>
                </div>
              </div>
              <div className="image-container col-lg-3 col-md-6">
                <div className='img_flex'>
                  <img src={image3} alt="" />
                </div>
                <div className="image-content">
                  <h3>Feel it Yourself</h3>
                  <p>
                    Visit one of our retail stores near you to take a nap test.
                    
                  </p>
                  <Link to="/Locate" rel="noopener noreferrer"><button className="cta-button1">Find Retailer</button></Link>
                </div>
              </div>
              <div className="image-container col-lg-3 col-md-6">
                <div className='img_flex'>
                  <img src={image4} alt="" />
                </div>
                <div className="image-content">
                  <h3>Talk to us</h3>
                  <p>
                    Talk to our sleep experts at +91 9990131819 & find the best mattress of your dreams.
                  </p>
                  <button className="cta-button1" onClick={handleCallNow}>Call now</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
