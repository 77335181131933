import React from 'react';
import Navbar2 from '../Navbar2/Navbar2';
import './PrivacyPolicy.css';
import Privacy from '../Components/wooden-sale-lettering-wooden-houses.jpg';
import Container from '@mui/material/Container';

const PrivacyPolicy = () => {
  return (
    <div className='policy'>
      {/* <div className='Navbar1'> */}
      <Navbar2 />
      {/* </div> */}
      <div className="image-container_1">
        <img src={Privacy} alt="Services Image" className="Services-image" />
        <div className="text">
          <h1>Privacy Statement</h1>
        </div>
      </div>
      <div className='content-container'>
        <Container>
          <h3>Objective:</h3>
          <p>
            This Privacy Statement articulates the privacy and data protection principles followed by ComfsyRiser Limited (hereinafter referred to as “ComfsyRiser”) and its entities around the world with regard to the personal information of its customers (including products, outsourcing and other services clients), partners, employees (current or former employees, trainees), applicants, contractors, prospects and vendors and current or former members of the Board of Directors, whose personal information are processed by ComfsyRiser ("Data Subjects").
          </p>
          <p>
            This Privacy Statement explains the use and protection of personal information collected by ComfsyRiser. It applies to any personal information you provide to ComfsyRiser and, subject to local law, any personal information we collect from other sources.
          </p>
          <p>
            Throughout this Privacy Statement, “ComfsyRiser” refers to ComfsyRiser Limited, including its affiliates and subsidiaries (also referred to as “we”, “us”, or “our”).
          </p>

          <h3>Scope of this Privacy Statement :</h3>
          <ol>
            <li>This Privacy Statement covers our information practices, including how we collect, use, share, and protect the personal information you provide to us, through our web sites that link to this Privacy Statement (collectively referred to as “ComfsyRiser’s Websites”).</li>
            <li>ComfsyRiser’s websites are not directed at nor targeted at children. No one who has not reached the age of thirteen may use the websites unless supervised by an adult. By accessing this website, you represent and warrant that you are 13 years of age or older.</li>
            <li>ComfsyRiser will not be liable for any unsolicited information provided by you. You consent to ComfsyRiser using such information as per ComfsyRiser’s Privacy Statement.</li>
          </ol>

          <h3>Personal Information Collected by ComfsyRiser:</h3>

          <ol>
            <li>Personal information provided by you:</li>
            <p>We may collect personal information such as your First Name, Last Name, E-mail Address, Job Title, Organization Name, Country, City, and Phone Number when you provide information that enables us to respond to your request for products or services. We will, wherever permissible by relevant laws, collect, use, and disclose this information to third parties for the purposes described in this Privacy Statement.</p>

            <li>Personal information provided through websites or emails:</li>
            <p>
              We may collect personal information such as your First Name, Last Name, E-mail Address, Job Title, Organization Name, Country, City, and Phone Number, Industry type when you fill forms on or raise a query through our websites, or send emails, call us, or write to us.
            </p>
          </ol>

          <h3>How We Use Your Data:</h3>

          <ul >
            <li>Provide information and services as requested by you.</li>
            <li>Assess queries, requirements, and process requests for products and services.</li>
            <li>Provide subscription-related services and information.</li>
            <li>Enable you to download collaterals and marketing materials.</li>
            <li>Perform client communication, service, billing, and administration.</li>
            <li>Conduct data analysis.</li>
            <li>Assess website performance and usage analysis.</li>
            <li>Maintain leads.</li>
            <li>Run marketing or promotional campaigns.</li>
            <li>Create brand awareness.</li>
            <li>Provide better services and generate demand.</li>
            <li>Market products and services based on legitimate business interest under the applicable law; or</li>
            <li>Conduct processing necessary to fulfil other contractual obligations for the individual.</li>
          </ul>

          <p>The legal basis may differ depending on applicable local laws, but generally we consider that our legitimate interests justify the processing; we find such interests to be justified considering that the data is limited to browsing activities related to what is considered business or professional related (our website does not offer any content directed to individual consumers as well as any content which might be used for any inferences about your private life habits or interests), we provide easy opt-out and limit the retention of data. The data that we may receive directly from you is completely voluntary and at your option. Where consent is required specifically, we will obtain consent before processing.</p>
          <p>Sharing of personal information with the third parties:</p>
          <p>ComfsyRiser does not share personal information about you with affiliates, partners, service providers, group entities and non-affiliated companies except:</p>

          <ol type='a'>
            <li>to provide products or services you’ve requested;</li>
            <li>when we have your permission: or</li>
            <li>under the following circumstances:
              <ol type='i'>
                <li>We may share information with affiliated entities/subsidiaries/branch offices for legitimate business purposes.</li>
                <li>We may provide the information to trusted entities who work on behalf of or with ComfsyRiser under strict confidentiality agreements. These entities may use your personal information to help ComfsyRiser communicate with you about offers from ComfsyRiser and for other legitimate business purposes. However, these companies do not have any independent right to further share or disseminate this information.</li>
                <li>We may share information with statutory authorities, government institutions, law enforcement agencies or other bodies where required for compliance with legal requirements.</li>
                <li>We may use the information to respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</li>
                <li>We may share information where we believe it is necessary in order to investigate, prevent, or take action against any illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or as otherwise required by law.</li>
                <li>We may share information with our empanelled vendors for assistance w.r.to setting up Business meetings post your consent.</li>
                <li>We may share information where we believe it is necessary in order to protect or enforce ComfsyRiser’s rights, usage terms, intellectual or physical property or for safety of ComfsyRiser or associated parties.</li>
                <li>We may share information about you if ComfsyRiser is acquired by or merged with another company after ensuring such company offers equivalent level of protection for personal data</li>
              </ol>
            </li>
          </ol>

          <h3>Preferences:</h3>
          <p>ComfsyRiser takes reasonable steps to keep your personal information accurate and complete. You can access or update your personal information in the following ways.</p>

          <h3>Profile</h3>
          <p>If you have created a profile or account on one of our websites, you can update your contact information after you log into your account.</p>

          <h3>Newsletters</h3>
          <p>If you request electronic communications, such as an e-newsletter, you will be able to unsubscribe at any time by following the instructions included in the communication.</p>

          <h3>Mobile Devices</h3>
          <p>If you previously chose to receive push notifications on your mobile device from us but no longer wish to receive them, you can manage your preferences either through your device or the application settings. If you no longer wish to have any information collected by the mobile application, you may uninstall the application by using the uninstall process available on your mobile device.</p>

          <h3>Cookies and other technologies</h3>
          <p>A cookie is a small file, typically of letters and numbers, downloaded to a user's computer or mobile device when the user accesses certain websites. Cookies are then sent back to originating website on each subsequent visit. Cookies are useful because they allow a website to recognise a user’s device. They are used for a wide variety of purposes such as website analytics – counting visitors and their behaviour, targeted advertising, recording user preferences or for authentication.</p>
          <p><b>Strictly Necessary Cookies -</b> These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>
          <p><b>Performance cookies -</b> These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>
          <p><b>Functionality cookies -</b> These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies, then some or all of these services may not function properly.</p>
          <p>Our website also includes functionality to interact with social media websites where you may have accounts, such as Facebook, Twitter and LinkedIn (“Third Party Accounts”). You should be aware that these sites may set cookies while you’re using this website, and use of these features may result in the collection or sharing of information about you. We encourage you to review your privacy settings on the social media sites with which you interact, together with their privacy policies, to make sure you understand the information that could be shared by those sites. The following Pixel/Tags are used for social media
            LinkedIn Insights Tag for Conversion Tracking. Terms and Conditions -
            <a href=''>Facebook Pixel </a> - Terms and Conditions for use
            <a href=''>Twitter</a> and  Conversion Tracking Program. <a href='/Terms'>Terms and Conditions.</a> </p>

          <h3>Email:</h3>
          <p>You can contact us at the e-mail or postal address listed in the "Contact Us" section at the bottom of this Statement. Please include your current contact information, the information you are interested in accessing and your requests with regard to the same if any. We will endeavour to respond to your request, subject to reasonable limitations provided by law, unless it infringes on the privacy of other individuals.</p>
          <p>If we are unable to entertain your request, we will provide you with the reason for refusal and inform you of any exceptions relied upon.</p>

          <h3>Anti-Spam policy:</h3>
          <p>ComfsyRiser recognizes the receipt, transmission or distribution of spam emails (i.e. unsolicited bulk emails) as a major concern and has taken reasonable measures to minimize the transmission and effect of spam emails in our computing environment. All emails received by ComfsyRiser are subject to spam check. Any email identified as spam will be rejected with sufficient information to the sender for taking necessary action. With this measure, along with other technical spam reduction measures, ComfsyRiser hopes to minimize the effect of spam emails. ComfsyRiser reserves the right to reject and/or report any suspicious spam emails, to the authorities concerned, for necessary action.</p>

          <h3>Data Protection and Confidentiality:</h3>
          <p>ComfsyRiser has implemented reasonable and adequate physical, technical and administrative security measures to protect personal information from loss, misuse, alteration or destruction.</p>
          <p>ComfsyRiser discloses information to third parties only for legitimate purposes such as our service providers and agents who are bound to maintain the confidentiality of personal information and may not use the information for any unauthorized purpose. ComfsyRiser will ensure through formally executed contracts that the service providers are committed to “same level of data protection” as applicable data protection laws and regulations.</p>
          <p>ComfsyRiser will take reasonable steps to ensure that all dealings in Personal Information, shall:</p>
          <ul>
            <li>be processed fairly & lawfully.</li>
            <li>be obtained only with the consent of the information provider.</li>
            <li>be collected, only when:
              <ol type='i' className='ol_roman_sm'>
                <li> other information is collected for a lawful purpose connected with a function or activity of ComfsyRiser; and</li>
                <li> other collection of the sensitive personal data or information is considered necessary for that purpose.</li>
              </ol>
            </li>

            <li>be available for review by the information provider, as and when requested.</li>
            <li>be kept secure against unauthorized or unlawful processing and against accidental loss, destruction or damage, as per the prescribed standards to protect the quality and integrity of your personal information.</li>
          </ul>
          <h3><b>Your ("Data Subjects”) rights </b>with respect to your personal information :</h3>

          <p>your rights may differ depending on applicable data protection local laws. We respect your right to be informed, access, correct, request deletion or request restriction, portability, objection, and rights in relation to automated decision making and profiling, in our usage of your personal information as may be required under applicable law. We also take steps to ensure that the personal information we collect is accurate and up to date. Subject to such laws, you may have the following rights :</p>

          <ul>
            <li>You have the right to know what personal information we maintain about you</li>
            <li>We will provide you with a copy of your personal information in a structured, commonly used and machine-readable format on request</li>
            <li>If your personal information is incorrect or incomplete, you have the right to ask us to update it</li>
            <li>You have the right to object to our processing of your personal information</li>
            <li>You can also ask us to delete or restrict how we use your personal information, but this right is determined by applicable law and may impact your access to some of our services</li>
            <li>You can have the right to access your personal information</li>
            <li>You have a right to object to processing of your personal information where it is so conducted by automated means and involves any kind of decision-making</li>
            <li>Further, you have the option to file a complaint for suspected or actual violations of your data protection rights with the relevant supervisory authority if you are from EEA.</li>
          </ul>

          <p><b>Please note:</b> You will also not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you.</p>

          <h3>Right to opt out</h3>
          <p>ComfsyRiser respects your privacy considerations and hence provides an option to you, to not provide the data or information sought to be collected. </p>
          <p>Further, you can also withdraw your consent which was earlier given to ComfsyRiser (which shall not affect the lawfulness of processing based on consent before its withdrawal), and the same must be communicated to ComfsyRiser in writing. Please note you can always unsubscribe to marketing communication through clicking unsubscribe available in the marketing communication(s) you receive </p>

          <h3>Access and rectification</h3>
          <p>You have a right to request access to your personal information. You may send us a request for access.
            You also have the right to correct any inaccuracies in your personal information. If you have an account with the ComfsyRiser Site, this can usually be done through the appropriate "your account" or "your profile" section on the website Otherwise, you can send us a request to rectify your data by writing to us at <a href=''> https://www.ComfsyRiser.in. </a></p>
          <p>All of the above relevant rights (including ones as applicable under GDPR, LGPD, PIPEDA, ARCO Rights etc. but not limited to) can be exercised by writing to : <a href=''> https://www.ComfsyRiser.in</a></p>
          <p>To know more about how we process your information including transfers, you may reach out to: <a href=''> https://www.ComfsyRiser.in</a></p>

          <h3>Retention of personal information:</h3>
          <p>We will retain your personal information for as long as necessary to provide the services you have requested, or for other essential purposes such as performance of a contract, complying with our legal obligations, resolving disputes, and enforcing our policies.</p>
          
          <h3>Disclosure of information:</h3>
          <p>ComfsyRiser may share your personal information with your consent, or where the disclosure is necessary for compliance of a legal obligation or where required by government agencies mandated under law to procure such disclosure. It shall also take steps to ensure that the information transferred to a third party is not further disclosed by it except where permissible under law.</p>

          <h3>International transfer of Personal Information:</h3>
          <p>ComfsyRiser may transfer your personal information across geographical borders to ComfsyRiser entities or service providers working on our behalf in other countries with different data protection laws that may not be on par with that of the origin country.  A Transfer of personal information has a broad meaning and occurs when information is communicated, moved, accessed or otherwise sent to another country.</p>
          <p>In cases where personal information would need to be transferred outside of a country where ComfsyRiser operates, towards other jurisdictions, specific requirements may apply for such Transfer to be allowed.</p>
          <p>For instance, data protection laws of the EEA and of Switzerland authorize the Transfer of personal information:</p>

          <ul>
            <li>to countries which have been recognized by the European Commission as offering an adequate level of protection for personal information.</li>
            <li>if appropriate measures in compliance with the European Union data protection legal requirements are in place in order to ensure the protection and the confidentiality of personal information in the countries that do not offer an adequate level of protection.</li>
          </ul>
          <p>This is the case where the recipient ComfsyRiser entity or Third Party concludes with the exporting relevant entity of ComfsyRiser an agreement based on the European Union standard contractual clauses, to ensure the protection of personal information.</p>
          <p>ComfsyRiser may also Transfer personal information to a Third Party located outside the EEA or Switzerland without having to implement the above measures where one of the following conditions is met:</p>

          <ul>
            <li>the Data Subject has given her/his consent to ComfsyRiser for the Transfer her/his Personal Information;</li>
            <li>ComfsyRiser needs to carry out the transfer of personal information to perform or conclude a contract with the Data Subject; the transfer of personal information is necessary
              <ol type='i' className='ol_roman_sm'>
                <li>to protect the Data Subject's vital interests (i.e. in case of a life or death situation), or</li>
                <li>to allow ComfsyRiser to establish, exercise or defend a legal claim, or</li>
                <li>for reasons of public interest;</li>
              </ol>
            </li>
            <li>the Transfer covers personal information publicly available (for instance from a public register).</li>
          </ul>

          <h3>Authenticity:</h3>
          <p>ComfsyRiser shall not be responsible for the authenticity of the personal information supplied to it by its service providers, whose duty it is to ensure that the information provided by it or its employees is authentic.</p>
         
          <h3>Effective Date:</h3>
          <p>This Privacy Statement is effective from 07-June-2024 and it supersedes all existing polices on the subject matter.</p>
          
          <h3>Changes to this Privacy Statement:</h3>
          <p>Please note that this Privacy Statement may be subject to change from time to time. The revised Privacy Statement will accordingly be published on this page.  We will not reduce your rights under this Privacy Statement without your explicit consent. Please regularly check this Privacy Statement to ensure you are aware of the latest updates with respect to the same.</p>
          <p>This Privacy Statement was last updated on  07-June-2024</p>
        </Container>
      </div >
    </div >

  );
};

export default PrivacyPolicy;



