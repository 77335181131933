import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import "./Premium.css";

function Categories() {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <section id="contact">
      <div style={{ textAlign: "center" }}>
        <button className="ctaButton" onClick={toggleForm}>
          <FontAwesomeIcon icon={faComment} /> Need Assistance
        </button>
        {/* <h2>Catch more zzzz’s with our...</h2> */}
        {showForm && <AssistanceForm setShowForm={setShowForm} />}
      </div>
    </section>
  );
}

function AssistanceForm({ setShowForm }) {
  const handleCloseForm = () => {
    setShowForm(false);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form submission logic here
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <div className="closeButonBox">
          <button className="btn_Close" onClick={handleCloseForm} style={{ color: 'black' }}>
            <svg width="28px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000" />
            </svg>
          </button>
        </div>
        <h2>Have a query? Contact us</h2>
        <p>Please fill the form below & we will get back to you soon.</p>
        <form action="https://formsubmit.co/alfaimpex99@gmail.com" method="POST">
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label>Name:</label>
                <input type="text" name="name" required />
              </div>
              <div className="col">
                <label>Email:</label>
                <input type="email" name="email" required />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Phone:</label>
                <input type="tel" name="phone" required />
              </div>
              <div className="col">
                <label>City:</label>
                <input type="text" name="city" required />
              </div>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col">
                <label style={{ marginTop: "10px", alignSelf: "center" }}>
                  Message Query:
                </label>
                <br />
                <textarea
                  style={{ alignSelf: "center" }}
                  name="message"
                  required
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col">

                <div className="g-recaptcha" data-sitekey="YOUR_SITE_KEY"></div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button type="submit" className="submit-button" style={{ color: 'black' }}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Categories;
