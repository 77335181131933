import React from 'react';
import './Mattress.css';
import Navbar2 from '../Navbar2/Navbar2';
import Footer from '../Footer/Footer';
import MattressImage from '../Components/14511.jpg';
import MattressImage1 from '../Components/mattress1.jpg';
// import MattressImage2 from '../Components/mattress2.jpg';
import MattressImage3 from '../Components/mattress3.jpg';
import MattressImage4 from '../Components/mattress4.jpg';
// import MattressImage5 from '../Components/mattress5.jpg';
// import MattressImage6 from '../Components/IMG-20240603-WA0016.jpg';
import MattressImage7 from '../Components/mattress7.jpg';
import MattressImage8 from '../Components/mattress8.jpg';

const Topper = () => {
  return (
    <div>
      <Navbar2 />
      <div className="Mattress-container">
        <img src={MattressImage} alt="Mattress" />
        <h2 className='text-center'><b>Mattress</b></h2>
      </div>
      
      <div className="container">
        <div className="row">
        <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage1} alt="mattress 1" content="Bonnel Spring Mattress" />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage2} alt="mattress  2" content=" " />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage3} alt="mattress  3" content="Rebounded Foam Mattress" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage4} alt="mattress 4" content="Orthopadic Mattress " />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage5} alt="mattress  5" content=" " />
            </div> */}

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage6} alt="mattress  6" content="" />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage7} alt="mattress 7" content="Pocket Spring Mattress With knitted Fabric " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage8} alt="mattress 8" content="Pocket Spring Mattress With More Bouncing " />
            </div>

        </div>
      </div>
      
      <Footer />
    </div>
  );
}

const PillowImage = ({ src, alt, content }) => {
  return (
    <div className="Mattress-image">
      <img src={src} alt={alt} className="img-fluid" />
      <div className="mattress-details">
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Topper;
