import React from 'react';
import './MattressProtector.css';
import Navbar2 from '../Navbar2/Navbar2';
import Footer from '../Footer/Footer';
import MattressProtectorImage from '../Components/fantastic-hotel-room.jpg';
import MattressImage50 from '../Components/5555.jpg';
import MattressImage51 from '../Components/RoleAway.jpg';
import MattressImage52 from '../Components/image52.jpg';
import MattressImage53 from '../Components/image53.jpg';
import MattressImage54 from '../Components/image54.jpg';
// import MattressImage55 from '../Components/image55.jpg';
import MattressImage56 from '../Components/image56.jpg';
import MattressImage57 from '../Components/image57.jpg';

const MattressProtector = () => {
  return (
    <div>
      <Navbar2 />
      <div className="MattressProtector-container">
        <img src={MattressProtectorImage} alt="Mattress Protector" />
        <h2 className='text-center'><b>Accessories</b></h2>
      </div>
      
      <div className="container">
        <div className="row">
        <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage50} alt="protector1" content="Folding Mattress " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage51} alt="protector2" content=" Role Away Bed" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage52} alt="protector 3" content="Mattress Protector " />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage53} alt="protector4" content=" Role Away Bed" />
            </div>
        </div>
        <div className="row">
        <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage54} alt="protector 5" content="Mattress Protector " />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage55} alt="protector 6" content="Memory Foam Contour Pillow" />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage56} alt="protector 7" content="Organic Natural Latex Sheet" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={MattressImage57} alt="protector 8" content="Memory Foam pillow Standard" />
            </div>
         
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

const PillowImage = ({ src, alt, content }) => {
  return (
    <div className="Mattress-image">
      <img src={src} alt={alt} className="img-fluid" />
      <div className="mattress-details">
        <p>{content}</p>
      </div>
    </div>
  );
}

export default MattressProtector;
