import React from 'react';
import coverImage from '../Components/Locate_Retailer-Banner-12_1944x.webp';
import Navbar2 from '../Navbar2/Navbar2';
import Footer from '../Footer/Footer';
import './StoreLocator.css';

const StoreLocator = () => {
    return (
        <div>
            <Navbar2 />
            <div className="container">
                <div className='custom_container'>
                    <div className="content row">
                        <div className="image col-md-6">
                            <img src={coverImage} alt="Cover Image" />
                        </div>
                        <div className="details col-md-6">
                            <div className="address">
                                <h2>Store Address</h2>
                                <p>B-85, Sector 65, Noida, Uttar Pradesh 201301, India</p>
                            </div>
                            <div className="map">

                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.665170474765!2d77.38641987535759!3d28.60981997567713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef8e1725867f%3A0xfd9777e338a608db!2sb%2C%20C-85%2C%20C%20Block%2C%20Sector%2065%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1713857137647!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>


                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default StoreLocator;
