import React from 'react';
import './Pillows.css';
import Navbar2 from '../Navbar2/Navbar2';
import Footer from '../Footer/Footer';
import pillowImage from '../Components/women-bed-fighting-with-pillows.jpg';
import pillowImage1 from '../Components/pillow222.jpg';
import pillowImage2 from '../Components/pillow223.jpg';
import pillowImage3 from '../Components/pillow224.jpg';
// import pillowImage4 from '../Components/pillow225.jpg';
import pillowImage5 from '../Components/pillow226.jpg';
import pillowImage6 from '../Components/pillow227.jpg';
import pillowImage7 from '../Components/pillow228.jpg';
import pillowImage8 from '../Components/WhatsApp_Image_2024-03-09_at_14.47.18_805fc7aa-removebg-preview.png';

const Pillow = () => {
  return (
    <div>
      <Navbar2 />
      <div className="pillow-container">
        <img src={pillowImage} alt="Pillow" />
        <h2 className='text-center'><b>Pillow</b></h2>
      </div>
      <div className="content">

      </div>

      <div className='container'>
        <div className='custom_container'>
          <div className="pillow-images row">
          <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage1} alt="Pillow 1" content="Organic Natural Latex Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage2} alt="Pillow 2" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage3} alt="Pillow 3" content="Memory Foam Pillow with knitted Fabric" />
            </div>

            {/* <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage4} alt="Pillow 4" content="" />
            </div> */}

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage5} alt="Pillow 5" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage6} alt="Pillow 6" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage7} alt="Pillow 7" content="Memory Foam Pillow" />
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mx-auto'>
              <PillowImage src={pillowImage8} alt="Pillow 8" content="Neck Memory Foam Pillow" />
            </div>

          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

const PillowImage = ({ src, alt, content }) => {
  return (
    <div className="Topper-image">
      <img src={src} alt={alt} />
      <div className="Topper-details">
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Pillow;
