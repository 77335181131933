import React from 'react';
import WhatsAppIconImage from '../Components/whatsapp.png';
import './Whatsapp.css';

const WhatsAppIcon = () => {
  const openWhatsApp = () => {
    // Change the phone number to the one you want to open WhatsApp chat with
    const phoneNumber = '+91 9990131819';
    // Construct the WhatsApp URL
    const url = `https://wa.me/${phoneNumber}`;
    // Open WhatsApp in a new window or tab
    window.open('https://wa.me/919990131819', '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={openWhatsApp}>
      <img src={WhatsAppIconImage} alt="WhatsApp" />
    </div>
  );
};

export default WhatsAppIcon;


