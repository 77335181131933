import React from 'react';
// import { FaComment, FaMapMarkerAlt } from 'react-icons/fa';
// import img8 from '../Components/brand1.png';
// import img9 from '../Components/brand 2.png';
// import img10 from '../Components/brand3.png';
// import img11 from '../Components/brand4.png';
// import img12 from '../Components/brand5.png';
// import img13 from '../Components/brand6.png';
import './Brand.css';

const BrandSection = () => {
  // const logos = [img8, img9, img10, img11, img12, img13];

  return (
    <div>
      <h3>"India’s Most Loved Hybrid Luxury Mattress Brand." </h3>

      {/* <div className="brand-section"> */}
        {/* {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`Logo ${index + 1}`} className="brand-logo" />
        ))} */}
      {/* </div> */}
      {/* <br />
      <p>Award-Winning Comfort & Support, recommended by millions of satisfied sleepers. </p>
      <br /> */}
      {/* <div className="cta-buttons">
        <button className="cta-button" onClick={() => console.log('Need Assistance')}>
          <FaComment className="cta-icon" />
          Need Assistance
        </button>
        <button className="cta-button" onClick={() => console.log('Locate Store')}>
          <FaMapMarkerAlt className="cta-icon" />
          Locate Store
        </button>
        <br />
        <br />
      </div> */}
      {/* <br />  <br /> */}
    </div>
  );
};

export default BrandSection;
