import React from 'react';
import { Link } from 'react-router-dom'; // Importing Link from React Router DOM
import './Gallary.css';
import image1 from '../Components/pillow-sofa.jpg';
import image2 from '../Components/view-3d-mattress-with-clouds (2).jpg';
import image3 from '../Components/16867.jpg';
import image4 from '../Components/23952.jpg';

const GalleryComponent = () => {
  return (
    <div className='container'>
      <div className='custom_container'>
        <div id="gallery-section" className="gallery-section">
          <div className="image_container row">
            <div className="gallery-image col-lg-3 col-md-6 mx-auto">
              {/* <h3 className="image-heading">Pillows</h3> */}
              <img src={image1} alt="Pillows" />
              {/* Using Link to navigate to a specific page */}
              <Link to="/pillows"><button className="cta-button">Shop Pillows</button></Link>
            </div>
            <div className="gallery-image col-lg-3 col-md-6 mx-auto">
              {/* <h3 className="image-heading">Toppers</h3> */}
              <img src={image2} alt="Toppers" />
              <Link to="/toppers"><button className="cta-button">Shop Toppers</button></Link>
            </div>
            <div className="gallery-image col-lg-3 col-md-6 mx-auto">
              {/* <h3 className="image-heading">Mattress</h3> */}
              <img src={image3} alt="Mattress" />
              <Link to="/mattress"><button className="cta-button">Shop Mattress</button></Link>
            </div>
            <div className="gallery-image col-lg-3 col-md-6 mx-auto">
              {/* <h3 className="image-heading">Mattress Protector</h3> */}
              <img src={image4} alt="Accessories" />
              <Link to="/mattressprotector"><button className="cta-button">Accessories</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryComponent;
