import React, { useRef } from 'react';
import './Video.css';
import video1 from '../Components/video.mp4';
import image1 from '../Components/orthopedic (2).jpg'; 

const VideoSection = () => {
  const handleMouseEnter = (videoRef) => {
    videoRef.current.play().catch((error) => {
      console.error('Error playing video:', error);
    });
  };

  const handleMouseLeave = (videoRef) => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const videoRef1 = useRef(null);

  return (
    <div className="video-section">
      {/* <h1>Videos</h1> */}
      <br /><br />
      <div className="video-row">
        <div className="video-container"
          onMouseEnter={() => handleMouseEnter(videoRef1)}
          onMouseLeave={() => handleMouseLeave(videoRef1)}
        >
          <video ref={videoRef1} controls muted>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="image-container1">
        <img src={image1} alt="Images" />
      </div>
    </div>
  );
};

export default VideoSection;
