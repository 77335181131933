// FeatureSection.js
import React from 'react';
import './Feature.css';
import imageUrl from '../Components/3.jpg';

const FeatureSection = () => {
  const featureRef = React.createRef();

  return (
    <div ref={featureRef} id="feature-section" className="feature-section">
      <div className='imagecontainer '>
        <img src={imageUrl} alt="" />
      </div>
    </div>
  );
};

export default FeatureSection;
