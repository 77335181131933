import React from 'react';
import './Catogries.css';
import image3 from '../Components/story1.webp';
import image4 from '../Components/story2.webp';
import image5 from '../Components/story3.webp';

function Categories() {
  return (

    <section id="services">
      <div className='container'>
        <div className='custom_container'>
          <h2 id="test">Sleep Stories</h2>
          <br />
          <div className="service-container row">
            <div className="service col-md-4">
              <img src={image3} alt="" />
              <p> Organic Latex</p>
            </div>

            <div className="service col-md-4">
              <img src={image4} alt="" />
              <p>Everything You Need to Know About Luxury Hotel Mattress</p>
            </div>

            <div className="service col-md-4">
              <img src={image5} alt="" />
              <p>A Guide To Choosing The Right Mattress For Your Guests</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default Categories;
