import React from "react";
// import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
// import PopupForm from './Popup/Popup';
import Home from "./Homepage/Homepage";
import Catogry from './Catogries/Catogries';
import Premium from './Premium/Premium';
import ProductSection from "./Product/Product";
import BrandSection from "./Brand/Brand";
import FeatureSection from "./Feature/Feature";
import About from "./About/About";
import GalleryComponent from "./Gallery/Gallery";
import VideoSection from "./Videos/Video";
import Help from './Need/Need';
// import Awards from './Awards/Award';
import Feed from './Feedback/Feedback';
import Footer from './Footer/Footer';
import { Margin } from "@mui/icons-material";

const Heading = () => {
  return (
    <div className="App">
      {/* <PopupForm /> */}
      <Navbar />
      <header>
        <Home />
      </header>
      <BrandSection />
      <ProductSection />
      <Help />
      <Premium />
      <GalleryComponent />
      <VideoSection />
      {/* <Awards /> */}
      <Feed />
      <About />
      <Catogry />
      <FeatureSection />
      <Footer />

    </div>
  );
};

export default Heading;
