import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./Feedback.css";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function App() {
  const [screenSize, setScreenSize] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () => {
      window.innerWidth > 768 ? setScreenSize(false) : setScreenSize(true);
    });
  }, [screenSize]);

  useEffect(() => {
    window.innerWidth <= 768 ? setScreenSize(true) : setScreenSize(false);
  }, [screenSize]);

  const feedbackData = [
    {
      name: "Shivam Dubey",
      rating: 5,
      text: "I am just writing to say how pleased I was with our new Mattress and how amazingly simple it was to assemble such a Comfortable Mattress.",
    },
    {
      name: "Ahmad Riyaz",
      rating: 5,
      text: "I just wanted to say how pleased I am with my Latex Mattress!! It’s really comfortable and looks extremely cool!!",
    },
    {
      name: "Shashi Gupta",
      rating: 5,
      text: "We would like to thank you for the excellent service provided from buying our Matttress and Pillow, to the delivery. We would be more than happy to recommend your Comfsy Riser to people we know..",
    },
    {
      name: "Ravish Jaiswal",
      rating: 5,
      text: "Just a quick P.C. to say thankyou for our Comfsy Riser Mattress as we didn’t get to see you when it was delivered. We love it! Thanks",
    },
    {
      name: "Akhil Dubey",
      rating: 5,
      text: "I recently bought a Mattress from you which was delivered last Sunday in excellent condition which I thank you for",
    },
    {
      name: "Sweta Jaiswal ",
      rating: 5,
      text: " Thank you, thank you, for my new Nights in Mattress. It is just beautiful and my husband said that compared with other Mattress, it was a joy to assemble, so easy. And it certainly does not “rattle, move or squeak . Thank you again. ",
    },
  ];

  return (
    <div className="container">
      <div className="custom_container">
        <h2>What Customers Say</h2>
        <br />
        <div className="SliderWrapper ">
          <div>
            <Swiper
              slidesPerView={2} // or 'auto'
              freeMode={true}
              pagination={false}
              modules={[FreeMode, Autoplay, Pagination]}
              autoplay={{ delay: 2000 }}
              navigation
              spaceBetween={32}
              grabCursor="false"
              className="mySwiper"
            >
              {feedbackData.map((feedback, index) => (
                <>
                  <SwiperSlide key={index}>
                    <div className="feedback-slide">
                      <div className="rating">
                        {Array.from({ length: feedback.rating }, (_, i) => (
                          <span key={i} className="star">
                            &#9733;
                          </span>
                        ))}
                      </div>
                      <p>{feedback.text}</p>
                      <div
                        className="customer-info"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          marginLeft: "20px",
                        }}
                      >
                        <AccountCircleIcon
                          style={{
                            fontSize: 40,
                            color: "blue",
                            marginTop: "5px",
                            marginRight: "10px"
                          }}
                        />
                        <p className="customer-name">{feedback.name}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              ))}
            </Swiper>
          </div>
          <div className="pt-4 d-none">
            {screenSize ? (
              <>
                <Swiper
                  slidesPerView={2} // or 'auto'
                  freeMode={true}
                  pagination={false}
                  modules={[FreeMode, Autoplay, Pagination]}
                  autoplay={{ delay: 2000 }}
                  navigation
                  spaceBetween={32}
                  grabCursor="false"
                  className="mySwiper"
                >
                  {feedbackData.reverse().map((feedback, index) => (
                    <>
                      <SwiperSlide key={index}>
                        <div className="feedback-slide">
                          <div className="rating">
                            {Array.from({ length: feedback.rating }, (_, i) => (
                              <span key={i} className="star">
                                &#9733;
                              </span>
                            ))}
                          </div>
                          <p>{feedback.text}</p>
                          <div
                            className="customer-info"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              marginLeft: "20px",
                            }}
                          >
                            <AccountCircleIcon
                              style={{
                                fontSize: 40,
                                color: "blue",
                                marginTop: "5px",
                                marginRight: "10px"
                              }}
                            />
                            <p className="customer-name">{feedback.name}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
