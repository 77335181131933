import React, { useRef } from 'react';
import './Mind.css';
import Navbar2 from '../Navbar2/Navbar2';
import Footer from '../Footer/Footer';
import MindImage from '../Components/38444.jpg';
import Video from '../Components/Peacemind.mp4';

const Mind = () => {
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  return (
    <div>
      <Navbar2 />
      <div className="peace-container">
        <img src={MindImage} alt="Mind-Of-Peace" />
        <div className="content-overlay">
          {/* <h1><b>ComfsyRiser the <br/> only cause of Peace of Mind, <br/> is greater than happiness <br/>of any Kind.</b></h1> */}
        </div>
      </div>

      <div className='container'>
        <div className='custom_container'>
        <h2>Peace of Mind Guaranteed</h2>

          <div className='paragraph'>
            <p> <b>Our dedicated customer care team takes pride in their work and focus on always providing the best service and support.</b><br />
              ComfsyRiser is proud to set new standards with the “Peace of Mind Guaranteed” plan. The objective of this plan is to ensure a simple and hassle-free experience for all our valued customers.</p>
            <p>Our “Peace of Mind Guarantee” is based on three ethical, moral and fundamental values. Firstly a promise to ensure to deliver the best quality product, secondly providing before, during and after-sale service to our customers and lastly use of tested and certified materials.</p>
            <p>With ComfsyRiser  “Peace of Mind Guarantee” plan, the customer gets the advantages of all our advancements such as:</p>
            <p><b>ComfsyRiser’s Aero Sleep Technology </b>ensures each and every mattress made by Springfit has breathable open cell structured HR foam which eliminates heat and sweet from the body and maintain adequate temperature through your sleep.</p>
            <p><b>ComfsyRiser’s Certiguard Technology</b> ensures that the mattresses are free from harmful chemicals and false odour. This also includes rigorous testing of the products before the product hits the market.</p>
            <p><b>ComfsyRiser’s award-winning </b>customer care team takes pride in our work and focus on always providing the best service and support to our customers. ComfsyRiser’s certified mattress technicians will guide you through all our sleep products and help you to register your product warranty, we will be there for you right from the start.</p>
          </div>
          <div className="peace-video">
            <video
              ref={videoRef}
              src={Video}
              className="video"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              autoPlay
              muted
            />
          </div>
        </div>
      </div>


      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Mind;
