import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine, RiShoppingCartLine } from 'react-icons/ri';
import logo from '../Components/logo111.jpg';
import './Navbar.css';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const scrollToGallery = () => {
    const gallerySection = document.getElementById('gallery-section');
    if (gallerySection) {
      gallerySection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFeature = () => {
    const featureSection = document.getElementById('feature-section');
    if (featureSection) {
      featureSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToSleepStories = () => {
    const sleepStoriesSection = document.getElementById('services');
    if (sleepStoriesSection) {
      sleepStoriesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (

    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src={logo} alt='logo' style={{ height: '75px', width: '150px' }} />
          <p>ComfsyRiser</p>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link className="navbar-link" to="/">Home</Link>
            </li>
            <li class="nav-item">
              <Link className="navbar-link" to="/Shop">Shop Now</Link>
            </li>
            <li class="nav-item dropdown">
              <Link className="navbar-link no-border" onClick={scrollToGallery}>Shop by Categories</Link>
            </li>
            <li class="nav-item">
              <Link className="navbar-link" to="/Locate">Locate Retailer</Link>
            </li>
            <li class="nav-item">
              <Link className="navbar-link" onClick={scrollToFeature}>Hospitality</Link>
            </li>
            <li class="nav-item">
              <Link className="navbar-link" onClick={scrollToSleepStories}>Sleep Selector</Link> {/* Changed Link to call function */}
            </li>
          </ul>

        </div>
      </div>
    </nav>
  );
};

export default Navbar;
