import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Heading from "./Heading";
import "./App.css";
import Pillows from "./Pillows/Pillows";
import Toppers from "./Toppers/Topper";
import Matress from "./Matress/Mattress";
import MattressProtector from "./MattressProtector/MattressProtector";
import Shop from './Shop/Shop';
import Locate from './Location/StoreLocator';
import Terms from "./Terms/Term";
import Privacy from './Privacy/Privacy'
import Policyreturn from './Return-Policy/policyreturn'
import Mindimage from './Mind/Mind'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Heading />} />
        <Route path="/pillows" element={<Pillows />} />
        <Route path="/Toppers" element={<Toppers />} />
        <Route path="/mattress" element={<Matress />} />
        <Route path="/mattressProtector" element={<MattressProtector/>}/>
        <Route path="/Shop" element={<Shop />} />
        <Route path="/Locate" element={<Locate />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy/>} />
        <Route path="/Policy" element={<Policyreturn/>} />
        <Route path="/Mind" element={<Mindimage/>} />
      </Routes>
    </Router>
  );
}

export default App;
