import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import Image1 from "../Components/images11 home (1).jpg";
import Image2 from "../Components/images11 home (2).jpg";
import Image3 from "../Components/comfortable-modern-bedroom-with-elegant-bedding-lighting-generated-by-ai.jpg";
import Image4 from "../Components/mother-little-daughter-have-fun-home.jpg";
import "./Homepage.css";

export default function App() {
  // const imageStyle = {
  //   height: '650px',
  // };

  return (
    <div className="container-fluid p-0">
      <MDBCarousel interval={1500}>
        <MDBCarouselItem itemId={1}>
          <img src={Image1} className="d-block w-100 imageStyle" alt="..." />
        </MDBCarouselItem>
        <MDBCarouselItem itemId={2}>
          <img
            src={Image2}
            className="d-block w-100 imageStyle"
            // style={imageStyle}
            alt="..."
          />
        </MDBCarouselItem>
        <MDBCarouselItem itemId={3}>
          <img
            src={Image3}
            className="d-block w-100 imageStyle"
            // style={imageStyle}
            alt="..."
          />
        </MDBCarouselItem>
        <MDBCarouselItem itemId={4}>
          <img
            src={Image4}
            className="d-block w-100 imageStyle"
            // style={imageStyle}
            alt="..."
          />
        </MDBCarouselItem>
      </MDBCarousel>
    </div>
  );
}
