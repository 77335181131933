import React from 'react';
import './Policyreturn.css';
import coverImage from '../Components/wooden-sale-lettering-wooden-houses.jpg';
import Navbar2 from '../Navbar2/Navbar2';

const ReturnPolicyPage = () => {
  return (


    <div className="return-policy-container">
      <Navbar2 />
      <div className="image-container">
        <div className='img_parent'>
          <img src={coverImage} alt="Return Policy" className="cover-image" />
          <h1>Return Policy</h1>
        </div>
      </div>


      <div className='container'>
        <div className='custom_container'>
          <div className='content-text'>
            <p>Thank you for shopping with us! We want you to be completely satisfied with your purchase. If you're not satisfied for any reason, we offer a hassle-free return policy within [number of days] days of your purchase date. Please review the following guidelines to ensure a smooth return process:</p>

            <h3>Return Eligibility:</h3>

            <p>Items must be unused, undamaged, and in their original packaging.
              Any tags, labels, or stickers must still be attached to the product.
              Items eligible for return exclude [list any exceptions, such as perishable goods or personalized items].</p>

            <h3>Initiating a Return:</h3>

            <p>To initiate a return, please contact our customer service team at [customer service email or phone number] to obtain a Return Authorization (RA) number.
              Please provide your order number and reason for return when contacting us.</p>
            <h3>Return Shipping:</h3>

            <p>Customers are responsible for return shipping costs unless the return is due to a mistake on our part or a defective item.
              We recommend using a trackable shipping method to ensure the safe return of your item.</p>
            <h3>Refund Process:</h3>

            <p>Once we receive your returned item, our team will inspect it to ensure it meets our return policy criteria.
              Refunds will be issued to the original payment method within [number of days] days of receiving your return.
              Please note that shipping costs are non-refundable unless the return is due to a mistake on our part or a defective item.</p>
            <h3>Exchanges:</h3>

            <p>We currently do not offer exchanges. If you wish to exchange an item, please initiate a return as outlined above and place a new order for the desired item.</p>
            <h3>Damaged or Defective Items:</h3>

            <p>If you receive a damaged or defective item, please contact us immediately for assistance. We will work with you to resolve the issue promptly.</p>
            <h3>Final Sale Items:</h3>

            <p>Items marked as "Final Sale" are not eligible for return or exchange.</p>
            <h3>International Returns:</h3>

            <p>International returns may be subject to additional fees and customs duties. Customers are responsible for any associated costs.</p>
            <h3>Policy Changes:</h3>

            <p>We reserve the right to update or modify our return policy at any time. Any changes will be communicated on our website.</p>
            <p>If you have any questions or concerns regarding our return policy, please don't hesitate to contact our customer service team. </p>
            <h2 className='thanks'>Thank you for choosing </h2> <h2 className='thanks'>[ComfsyRiser]</h2>

          </div>
        </div>
      </div>
    </div>

  );
};

export default ReturnPolicyPage;
